import {  StravaBikeDetails } from "../models/StravaBike"
import { StravaBikesResponse, StravaBikeDecorator } from "../models/StravaBikesResponse"

export const createStravaBike = (bike: StravaBikesResponse): StravaBikeDetails & StravaBikeDecorator => {
  return {
    ...bike,
    selected: false,
    enriched: false,
    frame_type: 0,
    weight: 0,
    brand_name: '',
    model_name: '',
  }
}
