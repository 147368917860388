import * as React from "react"
import ForgotPasswordC from "./ForgotPassword"
import { useMutation } from "@apollo/client"
import { useSnackbar } from "notistack"
import { forgotPassword } from "./gql/forgotPassword"
import { ForgotPassword, ForgotPasswordVariables } from "./gql/__generated__/ForgotPassword"

export default function ForgotPasswordContainer() {
  const [submit, {loading}] = useMutation<ForgotPassword, ForgotPasswordVariables>(forgotPassword, {
    onCompleted: data => data.forgotPassword?.ok ? enqueueSnackbar(`Please check your mailbox`, {variant: "success"}) : enqueueSnackbar("An error occurred, please try again later.", {variant: "error"})
  })
  const {enqueueSnackbar} = useSnackbar()
  const handleSubmit = async (email: string) => {
    await submit({variables: {email}})
  }

  return (<ForgotPasswordC onSubmit={handleSubmit} loading={loading}/>)
}
