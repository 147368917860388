import {gql} from "@apollo/client"
import {partsFields} from "./partsFields"

export const bikePartsFields = gql`
${partsFields}
fragment bikePartsFields on BikePartEntity {
  id
  attributes {
    bike {
      data {
        id
        attributes {
          name
        }
      }
    }
    part {
      data {
        ...partsFields
      }
    }
    odometer
    milage
    retired
  }
}
`
