import { gql } from "@apollo/client"
import { partsFields } from "./partsFields"

export const createPart = gql`
${partsFields}
mutation AddPart($data: PartInput!) {
  createPart(data: $data) {
    data {
      ...partsFields
    }
  }
}
`
