import React, { ChangeEvent, FormEvent, SyntheticEvent } from "react"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import { styled } from "@mui/material/styles"
import Dialog from "@mui/material/Dialog"
import Typography from "@mui/material/Typography"
import LoadingButton from "@mui/lab/LoadingButton"
import { Part } from "../../../../shared/models/Part"
import { Option } from "./types"
import Autocomplete from "@mui/material/Autocomplete"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import { AddPart } from "../../../../shared/gql/__generated__/AddPart"

export interface AddPartModalProps {
  open: boolean
  handleClose: (reason: string, createdPart?: AddPart) => void
  part: string
  loading: boolean
  handleCreatePart: (part: Part) => void
  categories: Option[]
  brands: Option[]
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const StyledDialog = styled(Dialog)(({theme}) => ({
  "& .MuiPaper-root": {
    maxWidth: 370
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}))

function DialogTitleWithClose(props: DialogTitleProps) {
  const {children, onClose, ...other} = props

  return (
    <DialogTitle sx={{m: 0, p: 2}} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon/>
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface PartModel {
  name: string;
  brand: Option
  category: Option
}

const defaultOption = {label: "", id: ""}

const AddPartModal = ({part, handleClose, open, loading, handleCreatePart, categories, brands}: AddPartModalProps) => {
  const [model, setModel] = React.useState<PartModel>({
    name: part,
    brand: defaultOption,
    category: defaultOption
  })
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => setModel(model => ({
    ...model,
    [event.target.name]: event.target.value
  }))

  const handleAutocompleteChange = (modelName: string) => (event: SyntheticEvent, value: Option | null) => {
    setModel(model => ({
      ...model,
      [modelName]: value ? value : defaultOption
    }))
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    event.stopPropagation()
    handleCreatePart({name: model.name, brand: model.brand.id, category: model.category.id})
  }

  return <StyledDialog
    open={open}
    onClose={(event, reason) => handleClose(reason)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <form onSubmit={handleSubmit}>
      <DialogTitleWithClose id="customized-dialog-title" onClose={() => handleClose("close")}>
        Create new Part
      </DialogTitleWithClose>
      <DialogContent dividers>

        <Typography id="modal-modal-description">
          Here you can create a new Part which can be added to your bike(s). Parts created here are visible to all
          users.
        </Typography>

        <Grid container direction={"column"} spacing={2} marginTop={3}>
          <Grid item>
            <Autocomplete<Option>
              onChange={handleAutocompleteChange("category")}
              disablePortal
              fullWidth
              id="category"
              options={categories}
              renderInput={(params) => <TextField {...params} required label="Category"/>}
            />
          </Grid>
          <Grid item>
            <Autocomplete<Option>
              onChange={handleAutocompleteChange("brand")}
              disablePortal
              fullWidth
              id="brand"
              options={brands}
              renderInput={(params) => <TextField {...params} required label="Brand"/>}
            />
          </Grid>
          <Grid item>
            <TextField id="name" name="name" fullWidth required label="Name" variant="outlined" value={model.name}
                       onChange={handleChange}/>
          </Grid>
          {model.name && model.category.label && model.brand.label && (
            <Grid item>
              <Typography variant="caption">
                Displayed as: {model.name} {model.brand.label} {model.category.label}
              </Typography>
            </Grid>)
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton color="primary" type="submit" loading={loading} variant="outlined">
          Add
        </LoadingButton>
      </DialogActions>
    </form>
  </StyledDialog>
}

export default AddPartModal
