import * as React from "react"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import { useSearchParams } from "react-router-dom"
import Title from "../../pages/Layout/Title"
import { User } from "../../shared/models/User"
import { Alert } from "@mui/material"
import { ROUTES } from "../../routes"
import { Link as RouterLink } from "react-router-dom"
import { stravaConfig } from "../../stravaConfig"


export interface OnSendParams {
  code: string
  scope: string
}

export interface StravaConnectParams {
  onSendCode: (params: OnSendParams) => void
  user?: User
}

export default function StravaConnect({onSendCode, user}: StravaConnectParams) {
  const [params] = useSearchParams()
  if (params.has("code") && params.has("scope")) {
    onSendCode({code: params.get("code") as string, scope: params.get("scope") as string})
  }

  const handleStravaConnect = (event: React.MouseEvent) => {
    event.preventDefault()
    const params = new URLSearchParams(stravaConfig)
    window.location.href = "https://www.strava.com/oauth/authorize?" + params.toString()
  }

  const rtfl = new Intl.RelativeTimeFormat("en")

  return (
    <React.Fragment>
      <Title>Strava Integration</Title>
      {user?.stravaAcceptedScopes ? (
        <React.Fragment>
          <Alert severity="success">Connected</Alert>
          <Typography color="text.secondary" sx={{flex: 1}}>
            Access Token
            expires {rtfl.format((user.stravaTokenExpiresAt * 1000 - (new Date().getTime())) / 1000 / 60 / 60, "hours")}
          </Typography>
          <Link color="primary" component={RouterLink} to={ROUTES.importBikes}>
            Import Bikes from Strava
          </Link>
        </React.Fragment>
      ) : (<div>
        <Link color="primary" onClick={handleStravaConnect}>
          Connect Strava
        </Link>
      </div>)
      }
    </React.Fragment>
  )
}
