import SignIn from "./SignIn"
import { useMutation } from "@apollo/client"
import { login } from "./gql/login"
import { UsersPermissionsLoginInput } from "../../../__generated__/globalTypes"
import { useSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "../../routes"
import { Login, LoginVariables } from "./gql/__generated__/Login"

export default function SignInContainer() {
  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar()
  const [submit, {loading}] = useMutation<Login, LoginVariables>(login, {
    onCompleted: data=> {
      enqueueSnackbar(`Welcome back, ${data.login.user.username}! <3`, {variant: "success"})
      const token = data.login.jwt as string
      const user = data.login.user
      localStorage.setItem('jwt', token)
      localStorage.setItem('user', JSON.stringify(user))
      navigate(ROUTES.overview)
    }
  })
  const handleSubmit = async (input: UsersPermissionsLoginInput) => {
    await submit({variables: {input}})
  }

  return (<SignIn onSubmit={handleSubmit} loading={loading}/>)
}
