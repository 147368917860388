import React, { useEffect, useState } from "react"
import StravaConnect, { OnSendParams } from "./StravaConnect"
import { useNavigate } from "react-router-dom"
import { restClient } from "../../utils/restClient"
import { createUser } from "../../shared/factories/createUser"
import { User, UserConfig } from "../../shared/models/User"

export default function StravaConnectContainer() {
  const navigate = useNavigate()
  const [user, setUser] = useState<User>()

  const handleSendCode = async ({scope, code}: OnSendParams) => {
    const response = await restClient.post(`/api/strava/connect?scope=${scope}&code=${code}`)

    if (response.status !== 200) {
      // some error
      console.log("error", response)
    } else {
      navigate("/")
    }
  }

  useEffect(() => {
    restClient.get<UserConfig>("/api/users/me").then(resp => {
      if (resp.status !== 200) {

      } else {
        setUser(createUser(resp.data))
      }
    })

  }, [])


  return (<StravaConnect onSendCode={handleSendCode} user={user}/>)
}
