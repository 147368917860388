import React from "react"
import { CssBaseline, ThemeProvider } from "@mui/material"
import { BrowserRouter } from "react-router-dom"
import { SnackbarProvider, } from "notistack"

import { theme } from "./theme"
import App from "./App";
import { onError } from "@apollo/client/link/error";
import { ApolloClient, createHttpLink, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { globalErrorHandler } from "./shared/snackbar-graphql/globalErrorHandler";

const httpLink = createHttpLink({
  uri: '/graphql',
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("jwt")
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
})

const client = new ApolloClient({
  link: from([authLink, onError(globalErrorHandler({})), httpLink]),
  cache: new InMemoryCache()
})

function AppContainer() {
  return <>
    <ThemeProvider theme={theme}/>
    <CssBaseline/>
    <BrowserRouter>
      <SnackbarProvider>
        <App apolloClient={client}/>
      </SnackbarProvider>
    </BrowserRouter>
  </>
}

export default AppContainer