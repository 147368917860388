import * as React from "react"
import { Skeleton } from "@mui/material"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"


export interface BikeSkeletonProps {
  count: number
}

export const BikeSkeleton = ({count = 1}: BikeSkeletonProps) => {
  return (
    <>
      {[...Array(count)].map((value, i) => (
        <Grid key={i} item xs={12} md={6} lg={4}>
          <Skeleton height={200}>
          </Skeleton>
          <Skeleton>
            <Typography gutterBottom variant="h5">Some longer name to look like a bike</Typography>
          </Skeleton>
          <Skeleton>
            <Typography>laasdas dasd asdasdasdqw easdasd</Typography>
          </Skeleton>
          <Skeleton>
            <Typography>laasdas dasd asdasdasd</Typography>
          </Skeleton>
        </Grid>
      ))}
    </>
  )
}
