export interface FrameTypeConfig {
  id: string
  name: string
}

export class FrameType {

  id!: string
  name!: string

  constructor(config: FrameTypeConfig) {
    Object.assign(this, config)
  }

  static empty () {
    return new FrameType({id: '', name: ''})
  }

}
