import React from "react"
import UserBikesComponent from "./UserBikes"
import { useQuery } from "@apollo/client"
import { userBikes } from "../../shared/gql/userBikes"
import { UserBikes } from "../../shared/gql/__generated__/UserBikes"
import { createModelBike } from "../../shared/factories/createModelBike"
import { BikeSkeleton } from "./components/BikeSkeleton"

export default function UserBikesContainer() {
  const {data, loading} = useQuery<UserBikes>(userBikes)
  if (loading) {
    return (<BikeSkeleton count={3} />)
  }

  const modelBikes = data?.bikes?.data.map(createModelBike) ?? []

  if (data?.bikes?.data) {
    return (<UserBikesComponent bikes={modelBikes}/>)
  }

  return <></>
}
