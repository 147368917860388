import { StravaBikeDetails } from "../models/StravaBike"
import { BikeInput } from "../../../__generated__/globalTypes"

export const createBikeInput = (bike: StravaBikeDetails): BikeInput => {
  return {
    name: bike.name,
    brand: bike.brand_name,
    model: bike.model_name,
    stravaGearId: bike.id,
    totalOdometer: bike.distance,
    frame_type: bike.frame_type as unknown as string,
    weight: bike.weight,
    primary: bike.primary,
  }
}
