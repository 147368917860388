import { ErrorResponse } from "@apollo/client/link/error";
import { getNotifications } from "./getNotifications";
import { snackbarDispatcher } from "./snackbarDispatcher";

interface ApolloContext {
  statusCode?: number;
}

interface GlobalErrorHandlerOptions {
  context?: ApolloContext;
}

function globalErrorHandler({ context }: GlobalErrorHandlerOptions = {}) {
  return (error: ErrorResponse) => {
    if (snackbarDispatcher) {
      console.log('error',error)
      console.log('getNotifications(error)',getNotifications(error))
      getNotifications(error).forEach((notification) => snackbarDispatcher?.dispatch(notification));
    }

    if (context) {
      // We can also set the status code depending on the error code
      context.statusCode = 500;
    }
  };
}

export {globalErrorHandler}