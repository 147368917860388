import { gql } from "@apollo/client"

export const register = gql`
mutation register($input: UsersPermissionsRegisterInput!) {
  register(
    input: $input
) {
    jwt
    user {
      id
      username
      email
    }
  }
}
`
