import {
  UserBikes_bikes_data_attributes_frame_type_data
} from "../gql/__generated__/UserBikes"
import { FrameType } from "../models/FrameType"

export const createFrameType = (frameType: UserBikes_bikes_data_attributes_frame_type_data): FrameType => {
  return {
    id: frameType.id as string,
    name: frameType.attributes?.name as string,
  }
}
