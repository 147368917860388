export interface UserConfig {
  username: string;
  email: string;
  confirmed: boolean;
  blocked: boolean;
  role: string;
  // bikes?: (string | null)[] | null;
  stravaAccessToken: string
  stravaRefreshToken: string
  stravaAcceptedScopes: boolean
  stravaTokenExpiresAt: any
}

export class User {
  username: string
  email: string
  confirmed: boolean
  blocked: boolean
  role: string
  stravaAccessToken: string
  stravaRefreshToken: string
  stravaAcceptedScopes: boolean
  stravaTokenExpiresAt: any

  constructor(config: UserConfig) {
    this.stravaTokenExpiresAt = config.stravaTokenExpiresAt
    this.username = config.username
    this.email = config.email
    this.confirmed = config.confirmed
    this.blocked = config.blocked
    this.role = config.role
    this.stravaAccessToken = config.stravaAccessToken
    this.stravaAcceptedScopes = config.stravaTokenExpiresAt
    this.stravaRefreshToken = config.stravaRefreshToken
  }
}
