import * as React from "react"
import { SyntheticEvent } from "react"
import TextField from "@mui/material/TextField"
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import AddPartModal from "../AddPartModal/AddPartModal.container"
import { AddPart } from "../../../../shared/gql/__generated__/AddPart"
import extractPartTitle from "../../../../utils/extractPartTitle"
import Title from "../../../../pages/Layout/Title"
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

export interface PartOptionType {
  inputValue?: string;
  title: string;
  id?: string;
  defaultMilage: number
}

const filter = createFilterOptions<PartOptionType>()

export interface AddBikePart {
  part: string,
  odometer: number,
  milage: number
}

export interface BikePartFormProps {
  parts: PartOptionType[]
  addBikePart: (data: AddBikePart) => void
}

export const BikePartForm = ({parts, addBikePart}: BikePartFormProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const min = 0
  const max = 1000000
  const [part, setPart] = React.useState<PartOptionType | null>(null)
  const [odometer, setOdometer] = React.useState<number>(0)
  const [milage, setMilage] = React.useState<number>(0)
  const handleOdoChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let value = parseInt(event.target.value, 10)
    if (value > max) value = max
    if (value < min) value = min
    setOdometer(value)
  }
  const handleMilageChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let value = parseInt(event.target.value, 10)
    if (value > max) value = max
    if (value < min) value = min
    setMilage(value)
  }
  const resetForm = () => {
    setOdometer(0)
    setMilage(0)
    setPart(null)
  }
  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault()
    if (!part?.id) {
      return
    }
    addBikePart({
      part: part.id,
      odometer: odometer * 1000,
      milage: milage * 1000
    })
    resetForm()
  }
  const [open, setOpen] = React.useState(false)
  const handleClose = (reason: string, createdPart?: AddPart) => {
    if (reason === "close") {
      setOpen(false)
      const defaultMilage = createdPart?.createPart?.data?.attributes?.category?.data?.attributes?.defaultMilage ?? 0
      setPart({
        title: createdPart?.createPart?.data ? extractPartTitle(createdPart.createPart.data) : "",
        id: createdPart?.createPart?.data?.id ?? "",
        defaultMilage
      })
      setMilage(defaultMilage)
    }
  }

  return <form onSubmit={handleSubmit}>
    <Title>Add Part</Title>
    <Grid container flexDirection={"column"} spacing={2} alignItems={"stretch"} justifyContent={"center"}>
      <Grid item flexGrow={1}>
        <Autocomplete
          value={part}
          onChange={(event, newValue) => {
            if (typeof newValue === "string") {
              setPart({
                title: newValue,
                defaultMilage: 0
              })
            } else if (newValue && newValue.inputValue) {
              // Open popup to add part
              setOpen(true)
              setPart({
                title: newValue.inputValue,
                defaultMilage: 0
              })
            } else {
              setPart(newValue)
              if (newValue?.defaultMilage) {
                setMilage(newValue.defaultMilage)
              }
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params)

            const {inputValue} = params
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.title)
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
                defaultMilage: 0
              })
            }

            return filtered
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="bike-parts-select"
          options={parts}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue
            }
            // Regular option
            return option.title
          }}
          renderOption={(props, option) => <li {...props}>{option.title}</li>}
          sx={{width: "100%", minWidth: 200}}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} required label={isMobile ? "Select/create component" : "Select or create a component for your Bike"}/>
          )}
        />
      </Grid>
      <Grid item container justifyContent={"space-between"} gap={2} alignItems={"stretch"}>
        <Grid item flexGrow={1}>
          <TextField id="initial-odometer"
                     required
                     fullWidth
                     type="number"
                     label="Initial Odometer"
                     helperText="If you already logged some distance, enter it here"
                     variant="outlined"
                     InputProps={{inputProps: {max, min}}}
                     value={odometer}
                     onChange={handleOdoChange}/>
        </Grid>
        <Grid item flexGrow={1}>
          <TextField id="milage"
                     required
                     fullWidth
                     type="number"
                     label="Mileage"
                     helperText="The distance after which you get notified"
                     variant="outlined"
                     InputProps={{inputProps: {max, min}}}
                     value={milage}
                     onChange={handleMilageChange}/>
        </Grid>
      </Grid>
      <Grid item alignSelf={"flex-end"}>
        <Button variant="contained" type="submit">Add</Button>
      </Grid>
    </Grid>
    {part?.title && open && <AddPartModal open={open} handleClose={handleClose} part={part.title}/>}
  </form>
}
