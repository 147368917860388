import { AddBikePart, BikePartForm, PartOptionType } from "./BikePartForm"
import { useMutation, useQuery } from "@apollo/client"
import { parts } from "../../../../shared/gql/parts"
import { Parts } from "../../../../shared/gql/__generated__/Parts"
import { addBikeParts } from "../../../../shared/gql/addBikeParts"
import { AddBikeParts_createBikePart, AddBikePartsVariables } from "../../../../shared/gql/__generated__/AddBikeParts"
import { useSnackbar } from "notistack"
import extractPartTitle from "../../../../utils/extractPartTitle"

export interface BikePartFormContainerProps {
  bikeId: string
}

export const BikePartFormContainer = ({bikeId}: BikePartFormContainerProps) => {
  let mappedParts: PartOptionType[] = []
  const {enqueueSnackbar} = useSnackbar()
  const {data} = useQuery<Parts>(parts)
  const [addBikePart] = useMutation<AddBikeParts_createBikePart, AddBikePartsVariables>(addBikeParts)

  if (data) {
    data.parts?.data.map(part => mappedParts.push({
      title: extractPartTitle(part),
      id: part.id ?? "",
      defaultMilage: part?.attributes?.category?.data?.attributes?.defaultMilage ?? 0
    }))
  }

  const handleAddBikePart = async (value: AddBikePart) => {
    await addBikePart({variables: {data: {...value, bike: bikeId}}, refetchQueries: ["UserBikes", "BikeParts"]})
    enqueueSnackbar("Successfully added Part to your bike", {variant: "success"})
  }
  return <BikePartForm parts={mappedParts} addBikePart={handleAddBikePart}/>
}
