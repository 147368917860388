import React from "react"
import { useMutation, useQuery } from "@apollo/client"
import { bikeParts } from "./gql/bikeParts"
import { useParams } from "react-router-dom"
import BikePartsC from "./BikeParts"
import { BikeParts, BikePartsVariables } from "./gql/__generated__/BikeParts"
import { deleteBikePart } from "./gql/deleteBikePart"
import { DeleteBikePart, DeleteBikePartVariables } from "./gql/__generated__/DeleteBikePart"
import { useSnackbar } from "notistack"
import { createBikePart } from "../../shared/factories/createBikePart"
import { BikePart } from "../../shared/models/BikePart"
import { updateBikePart } from "../../shared/gql/updateBikePart"
import { UpdateBikePart, UpdateBikePartVariables } from "../../shared/gql/__generated__/UpdateBikePart"
import { BikePartFiltersInput } from "../../../__generated__/globalTypes"

export default function () {
  const {bikeId} = useParams()
  const [hideRetired, setHideRetired] = React.useState(true)
  const {enqueueSnackbar} = useSnackbar()
  const bikePartsFilters: BikePartFiltersInput = {bike: {id: {eq: bikeId}}}
  if (hideRetired) {
    bikePartsFilters["retired"] = {eq: false}
  }

  const {
    data: bikePartsData,
    loading
  } = useQuery<BikeParts, BikePartsVariables>(bikeParts, {variables: {filters: bikePartsFilters}})
  const [deletePart] = useMutation<DeleteBikePart, DeleteBikePartVariables>(deleteBikePart, {
    onCompleted: () => enqueueSnackbar("Successfully deleted part", {variant: "success"})
  })
  const [updatePart] = useMutation<UpdateBikePart, UpdateBikePartVariables>(updateBikePart, {
    onCompleted: () => enqueueSnackbar("Successfully updated part", {variant: "success"})
  })

  const bikePartsM: BikePart[] = bikePartsData?.bikeParts?.data.map(createBikePart) ?? []
  return (
    <BikePartsC
      loading={loading}
      onUpdate={({id, milage, retired}) => updatePart({
        variables: {
          id,
          data: {retired, milage, dateRetired: retired ? new Date() : undefined},
        },
        refetchQueries: ["BikeParts"]
      })
        .then(({data}) => {
          if (data?.updateBikePart?.data) {
            return createBikePart(data.updateBikePart.data)
          }
        })}
      onDelete={(id: string) => deletePart({
        variables: {id},
        refetchQueries: ["BikeParts"]
      })}
      parts={bikePartsM}
      bikeId={bikeId as string}
      {...{hideRetired, setHideRetired}}/>)
}
