import { gql } from "@apollo/client"
import { bikePartsFields } from "./bikePartsFields"

export const updateBikePart = gql`
${bikePartsFields}
mutation UpdateBikePart($id: ID!, $data: BikePartInput!) {
  updateBikePart(id: $id, data: $data) {
    data {
      ...bikePartsFields
    }
  }
}
`
