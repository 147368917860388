import * as React from "react"
import AddPartModal, { AddPartModalProps } from "./AddPartModal"
import { useMutation, useQuery } from "@apollo/client"
import { createPart } from "../../../../shared/gql/createPart"
import { Part } from "../../../../shared/models/Part"
import { AddPart, AddPartVariables } from "../../../../shared/gql/__generated__/AddPart"
import { useSnackbar } from "notistack"
import categoriesQuery from "../../../../shared/gql/categories"
import { Brands } from "../../../../shared/gql/__generated__/Brands"
import { Categories } from "../../../../shared/gql/__generated__/Categories"
import brandsQuery from "../../../../shared/gql/brands"
import { Option } from "./types"

export interface PartFormContainerProps extends Pick<AddPartModalProps, "open" | "handleClose" | "part"> {
}

const AddPartModalContainer = ({...props}: PartFormContainerProps) => {
  const {enqueueSnackbar} = useSnackbar()

  const [createPartMutation, {loading}] = useMutation<AddPart, AddPartVariables>(createPart)
  const {data: categories, error: categoriesError} = useQuery<Categories>(categoriesQuery)
  const {data: brands, error: brandsError} = useQuery<Brands>(brandsQuery)

  const handleCreatePart = async (part: Part) => {
    const createdPart = await createPartMutation({variables: {data: part}, refetchQueries: ["Parts"]})
    enqueueSnackbar("Successfully created new part", {variant: "success"})
    props.handleClose("close", createdPart.data ?? undefined)
  }
  let mappedCategories: Option[] = categories?.partCategories?.data.map(value => ({
    id: value.id as string,
    label: value.attributes?.name as string
  })) ?? []
  let mappedBrands: Option[] = brands?.partBrands?.data.map(value => ({
    id: value.id as string,
    label: value.attributes?.name as string
  })) ?? []

  return <AddPartModal {...props} loading={loading} handleCreatePart={handleCreatePart} categories={mappedCategories}
                       brands={mappedBrands}/>
}

export default AddPartModalContainer
