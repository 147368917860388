import { ProviderContext } from "notistack";
import { ErrorResponse } from "@apollo/client/link/error";
import { GqlError } from "./graphql-codegen-result";
import { getGqlErrors, isNetworkError } from "./parsing-helpers";

const NOTIFICATION_MAP: Record<GqlError, Parameters<ProviderContext["enqueueSnackbar"]>> = {
  [GqlError.NetworkError]: ["There seems to be an issue with the network connection, please try again.", { variant: "error" }],
  [GqlError.InternalServerError]: ["Something went wrong, please try again.", { variant: "error" }],
  [GqlError.BAD_USER_INPUT]: ["Invalid login details.", { variant: "error" }]
};

function getNotifications(error: ErrorResponse) {
  if (isNetworkError(error)) {
    return [NOTIFICATION_MAP[GqlError.NetworkError]];
  }

  return getGqlErrors(error).map(
    ({ extensions }) => {
      if (extensions.error?.message) {
        return [extensions.error.message, { variant: 'error' }]
      }
      // @ts-ignore
      return NOTIFICATION_MAP[extensions?.code ?? GqlError.InternalServerError] ?? NOTIFICATION_MAP[GqlError.InternalServerError]
    }
  );
}

export { getNotifications }