import {gql} from "@apollo/client"

export const partsFields = gql`
fragment partsFields on PartEntity {
  id
  attributes {
    name
    brand {
      data {
        id
        attributes {
          name
        }
      }
    }
    category {
      data {
        id
        attributes {
          name
          defaultMilage
        }
      }
    }
    product_data {
      data {
        id
        attributes {
          title_short
          default_deeplink
          image_url
        }
      }
    }
  }
}
`
