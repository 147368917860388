import { useSnackbar } from "notistack";
import { snackbarDispatcher } from "./snackbarDispatcher";
import { useEffect } from "react";

function useSnackbarDispatcher(): void {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const unsubscribe = snackbarDispatcher?.subscribe((args) => enqueueSnackbar(...args));

    return () => {
      unsubscribe?.();
    };
  }, [enqueueSnackbar]);
}

export {useSnackbarDispatcher}