import { UserBikes_bikes_data } from "../gql/__generated__/UserBikes"
import { UserModelBike } from "../models/UserModelBike"
import { createFrameType } from "./createFrameType"
import { FrameType } from "../models/FrameType"
import { createBikePart } from "./createBikePart"

export const createModelBike = (bike: UserBikes_bikes_data): UserModelBike => {
  return {
    id: bike.id as string,
    name: bike.attributes?.name as string,
    brand: bike.attributes?.brand as string,
    model: bike.attributes?.model as string,
    stravaGearId: bike.attributes?.stravaGearId as string,
    totalOdometer: bike.attributes?.totalOdometer as number,
    frame_type: bike.attributes?.frame_type?.data ? createFrameType(bike.attributes?.frame_type?.data) : FrameType.empty(),
    primary: bike.attributes?.primary as boolean,
    weight: bike.attributes?.weight as number,
    bike_parts: bike.attributes?.bike_parts?.data?.length ? bike.attributes?.bike_parts.data.map(createBikePart) : [],
    activities: bike.attributes?.activities?.data?.length ?? 0
  }
}
