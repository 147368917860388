import * as React from "react"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import ListItemText from "@mui/material/ListItemText"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import Checkbox from "@mui/material/Checkbox"
import { StravaBike } from "../../../shared/models/StravaBike"
import Typography from "@mui/material/Typography"
import { StravaBikeDecorator } from "../../../shared/models/StravaBikesResponse"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export interface MultipleSelectCheckmarksProps {
  label: string
  options: (StravaBike & StravaBikeDecorator)[]
  onSelect: (bikes: StravaBike[]) => void
}

export default function MultipleSelectCheckmarks({label, options, onSelect}: MultipleSelectCheckmarksProps) {
  const handleChange = (event: SelectChangeEvent<StravaBike[] | number[]>) => {
    const {
      target: {value},
    } = event
    const values = (value as (StravaBike & StravaBikeDecorator)[] | number[])
      .map(i => (typeof i === "number" ? options[i as any] : i))
      .map(b => ({
        ...b,
        selected: true
      }))
    onSelect(values)
  }

  return (
    <div>
      <FormControl sx={{m: 1, width: 300}}>
        <InputLabel id="multiple-checkbox-label">{label}</InputLabel>
        <Select<(StravaBike & StravaBikeDecorator)[]>
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={options.filter(bike => bike.selected)}
          onChange={handleChange}
          input={<OutlinedInput label={label}/>}
          renderValue={(selected: StravaBike[]) => selected && selected.map(bike => bike.name).join(", ")}
          MenuProps={MenuProps}
        >
          {options.map((bike) => (
            <MenuItem key={bike.id} value={bike as any} disabled={bike.alreadyExists}>
              <Checkbox checked={bike.selected} />
              <ListItemText primary={bike.name}/>
            </MenuItem>
          ))}
          {options.length === 0 && (
            <MenuItem>
              <Typography variant={"body2"}>No bikes found. <br/> Make sure you have added some on Strava.</Typography>
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  )
}
