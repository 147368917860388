import { gql } from "@apollo/client"
import { bikePartsFields } from "../../../shared/gql/bikePartsFields"

export const bikeParts = gql`
${bikePartsFields}
query BikeParts($filters: BikePartFiltersInput) {
  bikeParts(filters: $filters, pagination: { page: 1, pageSize: 100 }) {
    data {
      ...bikePartsFields
    }
  }
}
`
