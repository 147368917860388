import { gql } from "@apollo/client"

export default gql`
query Categories {
  partCategories(pagination: { page: 1, pageSize: 100 }) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
`
