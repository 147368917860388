import * as React from 'react';
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Title from "../pages/Layout/Title"

interface HeadlineProps {
  children?: React.ReactNode;
}

export default function HeadlineWidget(props: HeadlineProps) {
  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Title gutterBottom={false}>
          {props.children}
        </Title>
      </Paper>
    </Grid>

  );
}
