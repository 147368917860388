export const ROUTES = {
  overview: "/",
  signup: "/signup",
  forgotPassword: "/forgot-password",
  importBikes: "/import-bikes",
  signin: "/signin",
  stravaConnect: "/strava-connect",
  bikeParts: "/bike-parts/:bikeId",
  getBikeParts: (bikeId: string)=> `/bike-parts/${bikeId}`
}
