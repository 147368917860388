import React from "react"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Title from "../Layout/Title"
import ImportBikesStepper from "./components/ImportBikesStepper"
import { StravaBike, StravaBikeDetails } from "../../shared/models/StravaBike"
import MultipleSelectCheckmarks from "./components/MultipleSelectCheckmarks"
import StravaBikeForm from "./components/StravaBikeForm"
import Divider from "@mui/material/Divider"
import BikeSummary from "./components/BikeSummary"
import { StravaBikeDecorator } from "../../shared/models/StravaBikesResponse"

interface ImportBikesProps {
  bikes: (StravaBikeDetails & StravaBikeDecorator)[]
  importBikes: (bikes: (StravaBikeDetails & StravaBikeDecorator)[]) => void
  setSelectedBikes: (bikes: StravaBike[]) => void
}

export default ({bikes, importBikes, setSelectedBikes}: ImportBikesProps) => {
  const selectedBikes = bikes.filter(b => b.selected)
  const handleSelect = (bikes: (StravaBike & StravaBikeDecorator)[]) => {
    setSelectedBikes(bikes)
  }
  const handleSubmit = () => {
    importBikes(selectedBikes)
  }
  const handleBikeChange = (key: number) => (bike: StravaBikeDetails) => {
    const newSelectedBikes = selectedBikes
    newSelectedBikes[key] = bike
    setSelectedBikes([...newSelectedBikes])
  }

  return (
    <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              minHeight: 240,
            }}
          >
            <Title>Import your Bikes from Strava</Title>

            <ImportBikesStepper steps={["Select bikes", "Confirm & Import"]} onSubmit={handleSubmit}>

              <div data-step="one">
                <Grid container justifyContent="center">
                  <Grid item>
                    <MultipleSelectCheckmarks label={"Select Bikes to import"} onSelect={handleSelect} options={bikes}/>
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-around" sx={{width: "fit-content", "& hr": {mx: 0.5, ml: 2}}}>
                  {selectedBikes.map((bike, key) =>
                    <>
                      <Grid item xs={5} marginBottom={3}>
                        <StravaBikeForm bike={bike} onChange={handleBikeChange(key)}/>
                      </Grid>
                      {/*selectedBikes.length % 2 === 0 && ((key +1) % 2 === 1) && <Divider orientation="vertical" flexItem/>*/}
                    </>
                  )}
                </Grid>
              </div>
              <div data-step="two">
                <Grid container spacing={4} marginTop={4} justifyContent="space-around">
                  {selectedBikes.map((bike, key) =>
                    <>
                      <Grid item key={bike.id}>
                        <BikeSummary bike={bike}/>
                      </Grid>
                    </>
                  )}
                </Grid>
              </div>
            </ImportBikesStepper>

          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
