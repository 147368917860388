import * as React from "react"
import { StravaBike } from "../../../shared/models/StravaBike"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import { Switch } from "@mui/material"
import formatMeter from "../../../utils/formatMeter"

interface BikeSummaryProps {
  bike: StravaBike
}

export default ({bike}: BikeSummaryProps) => (
  <Card sx={{minWidth: 275}}>
    <CardContent>
      <Typography variant="h5" component="div">
        {bike.name}
      </Typography>
      <Typography sx={{mb: 1.5}} color="text.secondary">
        {(bike.brand_name || bike.model_name) ? <>{bike.brand_name} ({bike.model_name})</> : <>&nbsp;</>}
      </Typography>
      <Typography variant="body2">
        Total distance: {formatMeter(bike.distance)}
        <br/>
        Retired: <Switch checked={bike.retired}/>
      </Typography>
    </CardContent>
  </Card>
)
