import * as React from "react"
import InputLabel from "@mui/material/InputLabel"
import { Select } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import { SelectChangeEvent } from "@mui/material/Select"
import { BikeOption } from "../../../shared/models/BikeOption"

interface BikeSelectProps {
  bikes: BikeOption[]
  value: string
  onChange: (bike: string) => void
}

const BikeSelect = ({bikes, value, onChange}: BikeSelectProps) => {
  const handleChange = (event: SelectChangeEvent) => onChange(event.target.value)
  const label = "Bike"
  return (
    <FormControl fullWidth>
      <InputLabel id="bike-select-label">{label}</InputLabel>
      <Select
        labelId="bike-select-label"
        id="bike-select"
        label={label}
        value={value}
        sx={{minWidth: 200}}
        onChange={handleChange}
        data-testid="bike-select"
      >
        {bikes.map(({id, label}) => (<MenuItem data-testid="bike-select-option" key={id} value={id}>{label}</MenuItem>))}
      </Select>
    </FormControl>
  )
}

export default BikeSelect
