import * as React from "react"
import Grid from "@mui/material/Grid"
import { UserBikeCard } from "./components/UserBike/UserBike"
import { UserModelBike } from "../../shared/models/UserModelBike"


export interface StravaConnectParams {
  bikes: UserModelBike[]
}

export default function UserBikes({bikes}: StravaConnectParams) {
  return (
    <>
      {bikes.map(bike => (
        <Grid key={bike.id} item xs={12} md={6} lg={4}>
            <UserBikeCard bike={bike}/>
        </Grid>
      ))}
    </>
  )
}
