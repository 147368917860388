import {
  UserBikes_bikes_data_attributes_bike_parts_data,
  UserBikes_bikes_data_attributes_bike_parts_data_attributes_part_data
} from "../gql/__generated__/UserBikes"
import { BikePart } from "../models/BikePart"
import extractPartTitle from "../../utils/extractPartTitle"
import { Parts_parts_data } from "../gql/__generated__/Parts"
import { BikeParts_bikeParts_data } from "../../pages/BikeParts/gql/__generated__/BikeParts"

export const createBikePart = (bikePart: UserBikes_bikes_data_attributes_bike_parts_data | BikeParts_bikeParts_data): BikePart => {
  return {
    id: bikePart.id as string,
    title: extractPartTitle(bikePart.attributes?.part?.data as UserBikes_bikes_data_attributes_bike_parts_data_attributes_part_data),
    odometer: bikePart.attributes?.odometer as number,
    milage: bikePart.attributes?.milage as number,
    retired: bikePart.attributes?.retired as boolean,
    shopLink: bikePart.attributes?.part?.data?.attributes?.product_data?.data?.attributes?.default_deeplink as string ?? '',
    imageLink: bikePart.attributes?.part?.data?.attributes?.product_data?.data?.attributes?.image_url as string ?? ''
  }
}
