import * as React from "react"
import Avatar from "@mui/material/Avatar"
import TextField from "@mui/material/TextField"
import Link from "@mui/material/Link"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import Copyright from "../Layout/Copyright"
import { Link as RouterLink } from "react-router-dom"
import { ROUTES } from "../../routes"
import { UsersPermissionsRegisterInput } from "../../../__generated__/globalTypes"
import LoadingButton from "@mui/lab/LoadingButton"
import { stravaConfig } from "../../stravaConfig"

export interface SignUpProps {
  onSubmit: (input: UsersPermissionsRegisterInput) => void
  loading: boolean
}

export default function SignUp({onSubmit, loading}: SignUpProps) {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const registerInput: UsersPermissionsRegisterInput = {
      username: data.get("email") as string,
      email: data.get("email") as string,
      password: data.get("password") as string,
    }
    onSubmit(registerInput)
  }

  const handleStravaSignup = (event: React.MouseEvent) => {

    event.preventDefault()
    const params = new URLSearchParams(stravaConfig)
    const url = "https://www.strava.com/oauth/authorize?" + params.toString()
    window.location.href = url

  }


  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" onSubmit={handleSubmit} marginTop={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
          <Grid container marginTop={3}>
            <Grid item>
              <Typography variant="caption">By clicking SIGN UP you agree to our privacy policy.</Typography>
            </Grid>
          </Grid>
          <LoadingButton
            size="small"
            loading={loading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{mb: 2}}
          >
            Sign Up
          </LoadingButton>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link component={RouterLink} to={ROUTES.signin} variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/*<Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button variant="outlined" sx={{bgcolor: '#fc5200', color: '#fff'}} onClick={handleStravaSignup}>Signup using Strava</Button>
      </Box>*/}
      <Copyright sx={{mt: 5}}/>
    </Container>
  )
}
