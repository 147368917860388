import React from "react"
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject
} from "@apollo/client"

import { Routing } from "./Routing"
import { useSnackbarDispatcher } from "./shared/snackbar-graphql/useSnackbarDispatcher";


interface AppProps {
  apolloClient: ApolloClient<NormalizedCacheObject>;
}

function App({apolloClient}: AppProps) {
   useSnackbarDispatcher()

  return <ApolloProvider client={apolloClient}>
    <Routing/>
  </ApolloProvider>
}

export default App
