import React from "react"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Title from "../Layout/Title"
import BikeSelect from "./components/BikeSelect.container"
import AddIcon from "@mui/icons-material/Add"
import MinusIcon from "@mui/icons-material/Remove"
import { BikePartFormContainer } from "../../widgets/UserBikes/components/BikePartForm/BikePartForm.container"
import { FetchResult } from "@apollo/client/link/core"
import { DeleteBikePart } from "./gql/__generated__/DeleteBikePart"
import BikePartsTable from "./components/BikePartsTable"
import { BikePart } from "../../shared/models/BikePart"
import FormControlLabel from "@mui/material/FormControlLabel"
import { Fab, Switch } from "@mui/material"

interface BikePartsProps {
  parts: BikePart[]
  bikeId: string
  onDelete: (id: string) => Promise<FetchResult<DeleteBikePart>>
  onUpdate: (data: BikePart) => Promise<BikePart | undefined>
  loading: boolean
  hideRetired: boolean
  setHideRetired: (retired: boolean) => void
}

export default ({ parts, bikeId, onDelete, onUpdate, loading, setHideRetired, hideRetired }: BikePartsProps) => {
  const [addPartVisible, setAddPartVisible] = React.useState(false)

  const handleAddPartClick = () => {
    setAddPartVisible(visible => !visible)
  }
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHideRetired(event.target.checked)
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {addPartVisible &&
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                minHeight: 240,
              }}
            >
              <BikePartFormContainer bikeId={bikeId}/>
            </Paper>
          </Grid>
        }

        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              minHeight: 240,
            }}
          >
            <Grid container gap={1} flexWrap={"wrap"} justifyContent="space-between" marginBottom={2}>
                <BikeSelect/>
            </Grid>

            <Grid container justifyContent={"space-between"}>
              <Title gutterBottom={false}>List of your Parts</Title>
              <FormControlLabel control={<Switch onChange={handleSwitchChange} defaultChecked={hideRetired}/>}
                                label="Hide retired parts"/>
            </Grid>
            <BikePartsTable loading={loading} initialRows={parts}
                            onUpdate={onUpdate} onDelete={onDelete}
                            hideRetired={hideRetired}/>

          </Paper>
        </Grid>
      </Grid>
      <Fab color="primary" aria-label="add" onClick={handleAddPartClick} sx={{ position: 'absolute', right: '20px', bottom: '20px' }}>
        {addPartVisible ? <MinusIcon/> : <AddIcon/>}
      </Fab>
    </Container>
  )
}
