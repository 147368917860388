import { gql } from "@apollo/client"
import { partsFields } from "./partsFields"

export const parts = gql`
${partsFields}
query Parts {
  parts(pagination: { page: 1, pageSize: 100 }) {
    data {
      ...partsFields
    }
  }
}
`
