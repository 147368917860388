import React, { FC } from "react"
import SignIn from "./pages/SignIn/SignIn.container"

const ProtectedRoute: FC<{ children: React.ReactElement }> = ({children}) => {
  const token = localStorage.getItem("jwt")

  if (!token) {
    return <SignIn/>
  }
  return children
}

export default ProtectedRoute
