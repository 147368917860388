import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { restClient } from "../../utils/restClient"
import ImportBikes from "./ImportBikes"
import { StravaBike, StravaBikeDetails } from "../../shared/models/StravaBike"
import { useMutation } from "@apollo/client"
import { importBikes } from "../../shared/gql/importBikes"
import { ROUTES } from "../../routes"
import { useNavigate } from "react-router-dom"
import { useSnackbar } from "notistack"
import { userBikes } from "../../shared/gql/userBikes"
import { createStravaBike } from "../../shared/factories/createStravaBike"
import { StravaBikeDecorator } from "../../shared/models/StravaBikesResponse"
import { ImportBikesVariables, ImportBikes as ImportBikesType } from "../../shared/gql/__generated__/ImportBikes"
import { createBikeInput } from "../../shared/factories/createBikeInput"

export default function () {
  const [stravaBikes, setStravaBikes] = useState<(StravaBikeDetails & StravaBikeDecorator)[]>([])
  const {enqueueSnackbar} = useSnackbar()
  const navigate = useNavigate()
  const [importUserBike] = useMutation<ImportBikesType, ImportBikesVariables>(importBikes)

  const handleImport = (bikes: StravaBikeDetails[]) => {
    bikes.forEach(async bike => {
      const bikeInput = createBikeInput(bike)
      await importUserBike({variables: {bike: bikeInput}, refetchQueries: [userBikes]})
    })
    navigate(ROUTES.overview)
  }

  const handleSetSelectedBikes = (selectedBikes: (StravaBike & StravaBikeDecorator)[]) => {
    setStravaBikes(previousBikes => {
      let toBeRemoved: (StravaBike & StravaBikeDecorator)[] = []
      if (previousBikes.filter(b => b.selected).length > selectedBikes.filter(b => b.selected).length) {
        // need to be deselected
        const prevB = previousBikes.filter(b => b.selected)
        const prevS = selectedBikes.filter(b => b.selected)
        toBeRemoved = prevB.filter(pB => !prevS.map(preS => preS.id).includes(pB.id))
      }
      const enrichedBikes = stravaBikes.map(b => {
        const selectedB = selectedBikes.filter(sB => b.id === sB.id)
        return {
          ...b,
          ...(selectedB.length ? selectedB[0] : {})
        }
      })
      return enrichedBikes.map(b => {
        if (toBeRemoved.length) {
          if (b.id === toBeRemoved[0].id) {
            b = {...b, selected: false}
          }
        }
        return {...b}
      })
    })
  }


  useEffect(() => {
    restClient.get<StravaBike[]>(`/api/strava/bikes`).then((response) => {
      if (response.status !== 200) {
        enqueueSnackbar("An error occurred importing your bikes, please try again", {variant: "error"})
      } else {
        const modelBikes = response.data.map(createStravaBike)
        setStravaBikes(modelBikes)
      }
    }).catch(error => {
      enqueueSnackbar(error.message, {variant: "error"})
    })
  }, [])

  const enrichBikeWithModelAndBrand = (selectedBike: StravaBike, updater: Dispatch<SetStateAction<(StravaBikeDetails & StravaBikeDecorator)[]>>, rawStravaBike: StravaBikeDetails) => {
    updater(bikes => bikes.map(bike => bike.id === selectedBike.id ? {
      ...selectedBike,
      model_name: rawStravaBike.model_name ?? "",
      brand_name: rawStravaBike.brand_name ?? "",
      frame_type: rawStravaBike.frame_type,
      weight: rawStravaBike.weight,
      enriched: true
    } : bike))
  }
  React.useEffect(() => {
    // fetch brand and model
    stravaBikes.filter(b => b.selected).map((selectedBike) => {
      if (!selectedBike.enriched) {
        restClient.get<StravaBikeDetails>(`/api/strava/bike/${selectedBike.id}`).then(resp => {
          if (resp.status !== 200) {
            console.log("error,", resp)
            return
          }
          enrichBikeWithModelAndBrand(selectedBike, setStravaBikes, resp.data)
        })
      }
    })
  }, [stravaBikes])

  return (stravaBikes && <ImportBikes importBikes={handleImport}
                                      bikes={stravaBikes}
                                      setSelectedBikes={handleSetSelectedBikes}/>)
}
