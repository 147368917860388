import * as React from "react"
import Box from "@mui/material/Box"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
} from "@mui/x-data-grid"
import { FetchResult } from "@apollo/client/link/core"
import { DeleteBikePart } from "../gql/__generated__/DeleteBikePart"
import { BikePart } from "../../../shared/models/BikePart"
import ConfirmDialog from "./ConfirmDialog"
import formatMeter from "../../../utils/formatMeter"
import Avatar from "@mui/material/Avatar";
import { LinearProgress, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";

interface BikePartsTableProps {
  initialRows: GridRowsProp
  onDelete: (id: string) => Promise<FetchResult<DeleteBikePart>>
  onUpdate: (data: BikePart) => Promise<BikePart | undefined>
  loading: boolean
  hideRetired: boolean
}

export default function BikePartsTable({ initialRows, onDelete, onUpdate, loading, hideRetired }: BikePartsTableProps) {
  const [promiseArguments, setPromiseArguments] = React.useState<any>(null)
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    setOpen(true)
    setPromiseArguments({ id })
  }

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })
  }

  const handleBuyClick = (row: GridRowModel) => () => {
    window.open(row.shopLink, "_blank");
  }

  const processRowUpdate = (newRow: GridRowModel) => {
    return onUpdate(newRow as BikePart)
  }

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const [open, setOpen] = React.useState(false)

  const handleConfirmClose = async (event: any, reason: string) => {
    const { id } = promiseArguments
    if (reason === "continue") {
      await onDelete(id)
    }

    setPromiseArguments(null)
    setOpen(false)
  }

  const columns: GridColDef[] = [
    {
      field: "imageLink",
      headerName: "Image",
      width: 60,
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params) =>
        <a href={params.row.shopLink} target="_blank"><Avatar src={params.value}/></a>,
    },
    { field: "title", headerName: "Part", width: 350, editable: false },
    {
      field: "milage",
      type: "number",
      align: "left",
      editable: true,
      headerAlign: "left",
      headerName: "Progress",
      width: 150,
      valueGetter: (params) => params.value / 1000,
      valueSetter: (params) => ({ ...params.row, milage: params.value * 1000 }),
      renderCell: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
        const percentage = row.odometer / row.milage * 100
        if (!isInEditMode) {
          return [
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <Tooltip title={`${formatMeter(row.odometer)} / ${formatMeter(row.milage)} km`}>
                  <LinearProgress sx={{ width: '100px' }} variant="determinate" value={percentage}/>
                </Tooltip>
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                  percentage,
                )}%`}</Typography>
              </Box>
            </Box>
          ]
        }
      },
    },
    {
      field: "actions",
      type: "actions",
      align: "right",
      headerAlign: "right",
      headerName: "Actions",
      width: 150,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon/>}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon/>}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon/>}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon/>}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<ShoppingCartCheckoutIcon/>}
            label="Buy"
            onClick={handleBuyClick(row)}
            color="inherit"
          />,
        ]
      },
    },
  ]
  if (!hideRetired) {
    columns.splice(columns.length - 1, 0, {
      field: "retired",
      headerName: "Retired",
      width: 80,
      editable: true,
      type: "boolean",
    })
  }
  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <ConfirmDialog open={open} handleClose={handleConfirmClose}
                     message={"Do you want to delete this part?"}></ConfirmDialog>
      <DataGrid
        rows={initialRows}
        loading={loading}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
      />
    </Box>
  )
}
