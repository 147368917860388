import React from "react"
import { useQuery } from "@apollo/client"
import { useNavigate, useParams } from "react-router-dom"
import BikeSelect from "./BikeSelect"
import { userBikes } from "../../../shared/gql/userBikes"
import { UserBikes } from "../../../shared/gql/__generated__/UserBikes"
import { createBikeOption } from "../../../shared/factories/createBikeOption"
import { BikeOption } from "../../../shared/models/BikeOption"
import { ROUTES } from "../../../routes"

export default function () {
  const navigate = useNavigate()
  const {bikeId} = useParams()
  const {data: bikes} = useQuery<UserBikes>(userBikes)
  const handleChange = (bike: string) => navigate(ROUTES.getBikeParts(bike))
  const mappedBikes: BikeOption[] = bikes?.bikes?.data.map(createBikeOption) ?? []

  return <BikeSelect bikes={mappedBikes} value={bikeId ?? ""} onChange={handleChange}/>
}
