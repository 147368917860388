import { gql } from "@apollo/client"

export const login = gql`
mutation Login($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
    user{id  username email}
  }
}


`
