import React from "react"
import { useRoutes } from "react-router-dom"
import Layout from "./pages/Layout/Layout"
import { ROUTES } from "./routes"
import { OverviewPage } from "./pages/Overview/OverviewPage/OverviewPage"
import ImportBikes from "./pages/ImportBikes/ImportBikes.container"
import SignUpContainer from "./pages/SignUp/SignUp.container"
import SignIn from "./pages/SignIn/SignIn.container"
import StravaConnectContainer from "./widgets/StravaConnect/StravaConnect.container"
import BikePartsContainer from "./pages/BikeParts/BikeParts.container"
import ProtectedRoute from "./ProtectedRoute"
import ForgotPasswordContainer from "./pages/ForgotPassword/ForgotPassword.container"


export const Routing = () => {

  const routes = useRoutes([{
      path: "/",
      element: <Layout/>,
      children: [
        {
          path: ROUTES.signup,
          element: <SignUpContainer/>,
        },
        {
          path: ROUTES.signin,
          element: <SignIn/>,
        },
        {
          path: ROUTES.forgotPassword,
          element: <ForgotPasswordContainer/>,
        },
        {
          path: ROUTES.overview,
          element: <ProtectedRoute><OverviewPage/></ProtectedRoute>,
        },
        {
          path: ROUTES.importBikes,
          element: <ProtectedRoute><ImportBikes/></ProtectedRoute>,
        },
        /*{
        path: "/example",
        element: <ExamplePage/>,
      }, */
        {
          path: ROUTES.stravaConnect,
          element: <ProtectedRoute><StravaConnectContainer/></ProtectedRoute>,
        },
        {
          path: ROUTES.bikeParts,
          element: <ProtectedRoute><BikePartsContainer/></ProtectedRoute>,
        }]
    }]
  )
  return <>{routes}</>
}
