import axios from "axios"


const getDefaultHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem("jwt")}`
})

export const restClient = {
  get: <T>(url: string) => axios.get<T>(url, {
    headers: getDefaultHeaders()
  }),
  post: (url: string, data = null) => axios.post(url, data, {
    headers: getDefaultHeaders()
  }),
}
