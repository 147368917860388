import { gql } from "@apollo/client"

export const addBikeParts = gql`
mutation AddBikeParts($data: BikePartInput!) {
  createBikePart(data: $data) {
    data {
      id
    }
  }
}
`
