import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Box from "@mui/material/Box"
import * as React from "react"
import { ChangeEvent } from "react"
import { StravaBikeDetails } from "../../../shared/models/StravaBike"

export interface StravaBikeFormProps {
  bike: StravaBikeDetails
  onChange: (bike: StravaBikeDetails) => void
}

export default function StravaBikeForm({bike, onChange}: StravaBikeFormProps) {
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {value, name} = event.target
    const changed = {...bike, [name]: value}
    onChange(changed)
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {checked, name} = event.target
    onChange({...bike, [name]: checked})
  }

  return (
    <Box component="form" sx={{mt: 3}}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="name"
            required
            fullWidth
            onChange={handleChange}
            value={bike.name}
            id="name"
            label="Name"
            autoFocus
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="brand_name"
            fullWidth
            onChange={handleChange}
            value={bike.brand_name}
            id="brand"
            label="Brand"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="model_name"
            fullWidth
            onChange={handleChange}
            value={bike.model_name}
            id="model"
            label="Model"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            type={"number"}
            fullWidth
            value={bike.distance}
            disabled
            id="distance"
            label="Distance in meter"
            name="distance"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            value={bike.id}
            disabled
            id="stravaId"
            label="Strava ID"
            name="stravaId"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            label="Retired"
            control={<Checkbox name="retired"
                               onChange={handleCheckboxChange}
                               checked={bike.retired}
                               color="primary"/>}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
