import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"


export interface ConfirmDialogProps {
  open: boolean
  handleClose: (event: any, reason: string) => void
  message: string

}

export default function ConfirmDialog({open, handleClose, message}: ConfirmDialogProps) {

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-title"
    >
      <DialogTitle id="confirm-dialog-title">
        {message}
      </DialogTitle>
      <DialogActions>
        <Button onClick={(event) => handleClose(event, "abort")}>No</Button>
        <Button onClick={(event) => handleClose(event, "continue")} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}
