import { gql } from "@apollo/client"
import { partsFields } from "./partsFields"
import { bikePartsFields } from "./bikePartsFields"

export const userBikes = gql`
${bikePartsFields}
query UserBikes {
  bikes {
    data {
      id
      attributes {
        name
        brand
        model
        stravaGearId
        totalOdometer
        weight
        primary
        createdAt
        updatedAt
        frame_type {
          data {
            id
            attributes {
              name
            }
          }
        }
        bike_parts {
          data {
            ...bikePartsFields
          }
        }
        activities {
          data {
            id
            attributes {
              stravaId
              description
              stravaSportType
              distance
              updatedAt
            }
          }
        }
      }
    }
  }
}
`
