import * as React from "react"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ListSubheader from "@mui/material/ListSubheader"
import DashboardIcon from "@mui/icons-material/Dashboard"
import PeopleIcon from "@mui/icons-material/People"
import LoginIcon from '@mui/icons-material/Login';
import AssignmentIcon from "@mui/icons-material/Assignment"
import { Link as RouterLink } from "react-router-dom"
import { ROUTES } from "../../routes"

export const mainListItems = (loggedIn: boolean, pathname: string) => (
  <React.Fragment>
    {loggedIn ?
      <React.Fragment>
        <ListItemButton component={RouterLink} to="/" selected={"/" === pathname}>
          <ListItemIcon>
            <DashboardIcon/>
          </ListItemIcon>
          <ListItemText primary="Dashboard"/>
        </ListItemButton>
        {/*<ListItemButton component={RouterLink} to={ROUTES.bikeParts} selected={ROUTES.bikeParts === pathname}>
          <ListItemIcon>
            <BikeScooterIcon/>
          </ListItemIcon>
          <ListItemText primary="Bike Parts"/>
        </ListItemButton>*/}
      </React.Fragment>
      : <React.Fragment>
        <ListItemButton component={RouterLink} to={ROUTES.signin} selected={ROUTES.signin === pathname}>
          <ListItemIcon>
            <LoginIcon/>
          </ListItemIcon>
          <ListItemText primary="Sign In"/>
        </ListItemButton>
        <ListItemButton component={RouterLink} to={ROUTES.signup} selected={ROUTES.signup === pathname}>
          <ListItemIcon>
            <PeopleIcon/>
          </ListItemIcon>
          <ListItemText primary="Sign Up"/>
        </ListItemButton>
      </React.Fragment>
    }
  </React.Fragment>
)

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon/>
      </ListItemIcon>
      <ListItemText primary="Current month"/>
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon/>
      </ListItemIcon>
      <ListItemText primary="Last quarter"/>
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon/>
      </ListItemIcon>
      <ListItemText primary="Year-end sale"/>
    </ListItemButton>
  </React.Fragment>
)
