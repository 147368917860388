import * as React from "react"
import Card from "@mui/material/Card"
import { CardActionArea, CardMedia, Tooltip } from "@mui/material"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import { Link } from "react-router-dom"
import { ROUTES } from "../../../../routes"
import { UserModelBike } from "../../../../shared/models/UserModelBike"
import formatMeter from "../../../../utils/formatMeter"

export interface UserBikeProps {
  bike: UserModelBike
}

export const UserBikeCard = ({bike}: UserBikeProps) => {

  const dertermineSVGFromFrameType = (frameType: string) => {

    switch (frameType) {
      case "1":
        return "/mountainbike.svg"
      case "2":
        return "/roadbike.svg"
      case "3":
        return "/roadbike.svg"
      case "4":
        return "/roadbike.svg"
      case "5":
        return "/roadbike.svg"
    }

  }
  return <Card sx={{height: 345}}>
    <CardActionArea sx={{height: "100%"}} component={Link} to={ROUTES.getBikeParts(bike.id as string)}>
      <CardMedia
        component="img"
        height="200"
        image={dertermineSVGFromFrameType(bike.frame_type.id)}
        alt="Road Bike"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" noWrap>
          <Tooltip title={bike.name + (bike.brand || bike.model ? " " + bike.brand + " (" + bike.model + ")" : "")}>
            <>{bike.name} {(bike.brand || bike.model) ? <>{bike.brand} ({bike.model})</> : <>&nbsp;</>}</>
          </Tooltip>
        </Typography>
        <Typography color="text.secondary">
          Total Odometer: {formatMeter(bike.totalOdometer)}
        </Typography>
        <Typography color="text.secondary">
          Number of parts: {bike.bike_parts.length}
        </Typography>
        <Typography color="text.secondary">
          Number of activities: {bike.activities}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
}
