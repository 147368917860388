import React from "react"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Container from "@mui/material/Container"
import StravaConnect from "../../../widgets/StravaConnect/StravaConnect.container"
import UserBikesContainer from "../../../widgets/UserBikes/UserBikes.container"
import Typography from "@mui/material/Typography"
import HeadlineWidget from "../../../widgets/HeadlineWidget"

export interface OverviewPageProps {
}

export const OverviewPage: React.FC<OverviewPageProps> = ({}: OverviewPageProps) => {
  const raw = localStorage.getItem("user")
  const user = raw ? JSON.parse(raw) : {}
  return (
    <Container maxWidth="lg" sx={{mt: 4, mb: 4}} data-testid="overview-page">
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 350,
            }}
          >
            <Typography variant="h5">Welcome {user.username}</Typography>
            <br/>
            <Typography variant="body1">First you can connect to your Strava account, this lets you import your existing
              Bikes directly from Strava.</Typography>
            This makes is easy to keep track of the kilometers you cycle per bike.
            <br/><br/>
            Once you imported your bike(s) you can add the components you have on this bike. We will then keep track of
            how much distance each component of yours has and notify you when its time to replace a specific component.
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 350,
            }}
          >
            <StravaConnect/>
          </Paper>
        </Grid>
        <HeadlineWidget>My Bikes</HeadlineWidget>
        <UserBikesContainer />
      </Grid>
    </Container>
  )
}
