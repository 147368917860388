import SignUp from "./SignUp"
import { useMutation } from "@apollo/client"
import { register } from "./gql/register"
import { UsersPermissionsRegisterInput } from "../../../__generated__/globalTypes"
import { register as registerT, registerVariables } from "./gql/__generated__/register"
import { useSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "../../routes"

export default function SignUpContainer() {
  const {enqueueSnackbar} = useSnackbar()
  const navigate = useNavigate()
  const [submit, {loading}] = useMutation<registerT, registerVariables>(register, {
    onCompleted: data => {
      // success we have a token:
      const token = data.register.jwt as string
      const user = data.register.user
      enqueueSnackbar("Welcome! <3", {variant: "success"})
      localStorage.setItem("jwt", token)
      localStorage.setItem("user", JSON.stringify(user))
      navigate(ROUTES.overview)

    }
  })
  const handleSubmit = async (input: UsersPermissionsRegisterInput) => {
    await submit({variables: {input}})
  }
  return (<SignUp onSubmit={handleSubmit} loading={loading}/>)
}
